<template>
  <v-app>
    <v-main class="login_background">
      <card-login title="Cadastrar" card_color="white">
        <template v-slot:form>
          <form-register :formData.sync="user" ref="form"></form-register>
        </template>
        <template v-slot:control>
          <v-row>
            <v-col>
              <v-btn text color="primary" to="/login">Voltar</v-btn>
            </v-col>
            <v-col>
              <v-btn :loading="loading" block color="primary" @click="clickSubmit">Cadastrar</v-btn>
            </v-col>
          </v-row>
        </template>
      </card-login>
    </v-main>
    <dialog-login-error v-if="show_dialog_error" :error_msg="error_msg" @close="handleClose"></dialog-login-error>
  </v-app>
</template>

<script>
export default {
  name: "Register",
  components: {
    CardLogin: () => import("@/_auth/components/containers/_auth_card_01.vue"),
    FormRegister: () => import("@/_auth/components/forms/Signup.vue"),
    DialogLoginError: () => import('../components/DialogLoginError.vue'),
  },
  data() {
    return {
      user: {
        name: null,
        email: null,
        birthdate: null,
        country: 'brazilian',
        state: null,
        city: null,
        phone: null,
        password: null,
        //role_ids: ,
        password_confirmation: null,
        curriculum_link: null
      },
      loading: false,
      timeout: 1000,

      show_dialog_error: false,
      error_msg: null,
    };
  },
  methods: {
    handleClose(){
      this.error_msg = null
      this.show_dialog_error = false
    },
    clickSubmit() {
      this.loading = true;

      setTimeout(() => {
        if (this.$refs.form.validateForm()) {
          this.submitRegister().then(() => {
            this.loading = false;
          });
        } else {
          this.loading = false;
        }
      }, this.timeout);
    },
    submitRegister() {
      return this.$auth
        .register({
          params: {
            name: this.user.name,
            email: this.user.email,
            birthdate: this.user.birthdate,
            country: this.user.country,
            state: this.user.state,
            city: this.user.city,
            phone: this.user.phone,
            password: this.user.password,
            password_confirmation: this.user.password_confirmation,
            curriculum_link: this.user.curriculum_link,
            confirm_success_url: origin
          },
        })

        .then(response => {
          this.$sweetAlerts.toast1("success", "E-mail de confirmação enviado!");
        })

        .catch(error => {
          console.log("Erro na tentativa de fazer cadastro..", error);
          this.$sweetAlerts.toast1("error", "Algo deu errado!");
          if (error && error.response && error.response.data && error.response.data.errors){
            let err = error.response.data.errors.full_messages[0]

            this.error_msg = err
            this.show_dialog_error = true
          }
        });
    }
  }
};
</script>

<style>
.login_background {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  background: url('../../assets/background_login.png');
  /* background: url('../../assets/background_login.jpg') */
}
</style>
